.textarea {
  width: 100%;
  min-height: 64px;
  padding: var(--space-2);
  margin: 0;
  resize: vertical;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-light-3);
  border-radius: 4px;
  transition: border-color 0.2s linear;
  will-change: border-color;
}

.textarea:focus {
  outline: none;
  border-color: var(--color-accent-1);
}

.textarea--error,
.textarea--error:focus {
  border-color: var(--color-error-1);
}
