.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-3);
  margin-bottom: var(--space-3);
}

.toolbar__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-3);
}

.toolbar__content--left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-3);
}
