.notification {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 10000;
  width: 30%;
  margin-left: -15%;
  overflow: hidden;
}

.notification__item {
  display: flex;
  align-items: center;
  margin: var(--space-3);
  padding: var(--space-3);
  color: var(--color-light-0);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  animation: slide 0.2s linear;
}

.notification_message {
  flex: 1;
}

.notification__close {
  padding: var(--space-1);
  color: var(--color-dark-2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.notification__close:hover {
  color: var(--color-secondary-1);
}

@keyframes slide {
  from {
    transform: translate(0, -32px);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .notification {
    width: 80%;
    margin-left: -40%;
  }
}
