.field__label {
  display: block;
  color: var(--color-dark-0);
  margin-bottom: var(--space-1);
}

.field__required::after {
  content: '*';
  padding-left: var(--space-1);
  color: var(--color-error-1);
}

.field__err {
  display: block;
  margin-top: var(--space-1);
  color: var(--color-error-1);
}
