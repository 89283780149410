.button {
  padding: var(--space-2) var(--space-3);
  font-size: var(--fontsize-1);
  color: var(--color-accent-1);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-accent-1);
  border: 1px solid var(--color-accent-1);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.button:active {
  position: relative;
  top: 1px;
}

.button:hover {
  color: var(--color-light-0);
  background: linear-gradient(
    60deg,
    var(--color-accent-0),
    var(--color-accent-1)
  );
  box-shadow: 2px 3px 0px 0px var(--color-accent-2);
}

.button:disabled {
  color: var(--color-dark-0);
  border-color: var(--color-light-3);
  background-color: var(--color-light-0);
  background-image: none;
  box-shadow: 2px 3px 0px 0px var(--color-light-3);
  cursor: not-allowed;
}
