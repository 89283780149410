.page {
  width: 100%;
  /* max-width: var(--breakpoint-3); */
  padding-bottom: var(--space-7);
  overflow-x: auto;
}

.page__title-container {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: var(--space-3);
  gap: var(--space-3);
}

.page__title {
  position: relative;
  padding: var(--space-2) 0;
  padding-left: var(--space-4);
  font-size: var(--fontsize-4);
  font-weight: 300;
  color: var(--color-dark-2);
}

.page__title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-3);
  height: 100%;
  background-color: var(--color-primary-1);
}

.page__toolbar {
  margin-bottom: var(--space-3);
}

.page__content {
  padding: var(--space-3) 0;
}
