.modal__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-3);
  height: var(--navbar-height);
  border-bottom: 1px solid var(--color-light-3);
  font-size: var(--fontsize-3);
}

.modal__header button {
  padding: 0;
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.modal__header button:hover {
  color: var(--color-secondary-1);
}

.modal__title {
  margin-left: var(--space-4);
}

.modal__title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-4);
  height: 100%;
  background-color: var(--color-warning-1);
}

.modal__content {
  padding: var(--space-4) var(--space-3);
  color: var(--color-dark-2);
}
