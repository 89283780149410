.button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: var(--space-2);
}

.button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fontsize-3);
}
