.definition {
  display: flex;
}

.definition__label {
  color: var(--color-dark-0);
}

.definition__value {
  white-space: pre-line;
}
