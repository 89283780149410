.popover {
  position: relative;
  display: flex;
}

.popover__text {
  margin: var(--space-2);
  font-size: var(--fontsize-4);
}

.popover__content {
  position: absolute;
  min-width: 150px;
  top: 0;
  left: 0;
  z-index: 1101;
  padding: var(--space-3) 0;
  margin-top: var(--space-4);
  background-color: var(--color-light-0);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
}
