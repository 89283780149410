.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  padding: 0 var(--space-3);
}

.navbar__button {
  padding: 0 var(--space-3);
  border: none;
  color: var(--color-dark-2);
}

.navbar__button__icon {
  padding: 0;
  border: none;
  font-size: var(--fontsize-4);
}

@media screen and (max-width: 576px) {
  .navbar__button {
    padding: 0;
  }
}
