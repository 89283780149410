.content {
  min-height: 100%;
  background-color: var(--color-light-2);
  transition: margin-left 0.3s;
}

.main {
  padding: var(--space-4);
}

@media screen and (max-width: 576px) {
  .main {
    padding: var(--space-3);
  }
}