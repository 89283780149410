@import-normalize;

:root {
  --breakpoint-0: 576px;
  --breakpoint-1: 768px;
  --breakpoint-2: 992px;
  --breakpoint-3: 1200px;

  --fontsize-0: 12px;
  --fontsize-1: 14px;
  --fontsize-2: 18px;
  --fontsize-3: 20px;
  --fontsize-4: 24px;
  --fontsize-5: 32px;
  --fontsize-6: 48px;
  --fontsize-7: 64px;

  --color-primary-0: #ef5b8d;
  --color-primary-1: #e91e63;
  --color-primary-2: #aa1649;
  --color-secondary-0: #45c2d1;
  --color-secondary-1: #00acc1;
  --color-secondary-2: #007e8d;
  --color-accent-0: #b761c5;
  --color-accent-1: #9c27b0;
  --color-accent-2: #721d81;
  --color-success-0: #7cc47f;
  --color-success-1: #4caf50;
  --color-success-2: #38803b;
  --color-warning-0: #ffb445;
  --color-warning-1: #ff9800;
  --color-warning-2: #ba6f00;
  --color-error-0: #f7766c;
  --color-error-1: #f44336;
  --color-error-2: #b23128;
  --color-light-0: #fff;
  --color-light-1: #f9f9f9;
  --color-light-2: #efefef;
  --color-light-3: #d0d0d0;
  --color-dark-0: #8c8c8c;
  --color-dark-1: #666;
  --color-dark-2: #444;
  --color-dark-3: #000;

  --space-0: 0px;
  --space-1: 4px;
  --space-2: 5px;
  --space-3: 16px;
  --space-4: 32px;
  --space-5: 64px;
  --space-6: 128px;
  --space-7: 256px;

  --sidebar-width: 250px;
  --navbar-height: 60px;
}

html,
body,
#root {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Helvetica, 'LiHei Pro', '微軟正黑體', sans-serif;
  color: var(--space-3);
}
