.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-3);
  padding: 0 var(--space-2);
  color: var(--color-dark-1);
}

.pagination__page-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination__page {
  padding: var(--space-2);
  color: var(--color-dark-1);
  background-color: transparent;
  border: none;
  outline: none;
}

.pagination__page:hover {
  color: var(--color-secondary-1);
}

.pagination__page:disabled {
  color: var(--color-dark-0);
  cursor: not-allowed;
}
