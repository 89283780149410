.section {
  margin-bottom: var(--space-4);
}

.section__title {
  position: relative;
  margin: 0;
  margin-bottom: var(--space-3);
  padding: var(--space-2) 0;
  padding-left: var(--space-4);
  font-size: var(--fontsize-3);
  font-weight: 300;
  color: var(--color-dark-2);
}

.section__title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-3);
  height: 100%;
  background-color: var(--color-primary-1);
}

.section__content {
  padding: var(--space-3);
  background-color: var(--color-light-0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}

.section__content__column {
  display: grid;
  grid-column-gap: var(--space-4);
  grid-row-gap: var(--space-3);
}
