.sidebar__wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: 1;
  background-image: url('../../assets/img/background.jpg');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.45);
  transition: 0.3s;
}

.sidebar__wrap::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 2;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--sidebar-width);
  z-index: 3;
}

.sidebar__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--navbar-height);
  margin-bottom: var(--space-4);
  border-bottom: 1px solid var(--color-dark-2);
}

.sidebar__logo__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 var(--space-3);
  background-color: var(--color-secondary-1);
  font-size: var(--fontsize-3);
}

.sidebar__logo__text {
  margin-right: var(--space-2);
  padding: var(--space-3);
  color: var(--color-light-2);
  font-size: var(--fontsize-2);
  white-space: nowrap;
  user-select: none;
}

.sidebar__link {
  position: relative;
  padding: var(--space-2) var(--space-3);
  margin: var(--space-2) 0;
  color: var(--color-light-3);
  transition: color 0.3s, padding 0.3s;
}

.sidebar__link::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  width: 0;
  height: 100%;
  background-color: var(--color-secondary-0);
  transition: width 0.3s;
}

.sidebar__link:hover {
  padding-left: calc(var(--space-3) + var(--space-2));
  color: var(--color-light-0);
}

.sidebar__link:hover::after {
  width: 8px;
}

.sidebar__link--active,
.sidebar__link--active:hover {
  padding-left: var(--space-3);
  color: var(--color-secondary-0);
}

.sidebar__link--active::after {
  width: 8px;
}

.sidebar__link__icon--left {
  padding-right: var(--space-3);
}

.sidebar__group {
  position: relative;
  padding: var(--space-2) var(--space-3);
  margin: var(--space-2) 0;
  color: var(--color-light-3);
  transition: color 0.3s, padding 0.3s;
}

.sidebar__group:hover,
.sidebar__group--active {
  color: var(--color-light-0);
}

.sidebar__group__content {
  padding-left: calc(var(--space-4) + var(--space-2) + var(--space-1));
  background-color: rgba(255, 255, 255, 0.075);
}

.sidebar__group__icon--left {
  padding-right: var(--space-3);
}
