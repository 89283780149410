.login__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/background.jpg');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.login__container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 2;
}

.login {
  width: 250px;
  padding-bottom: var(--space-3);
  background-color: var(--color-light-0);
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 3;
}

.login__header {
  position: relative;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-light-3);
  font-size: var(--fontsize-2);
  text-align: center;
}

.login__header::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-4);
  height: 100%;
  background-color: var(--color-secondary-1);
}

.login__form {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  padding: var(--space-4) var(--space-3);
}

.login__action {
  text-align: center;
}

.login button {
  padding: var(--space-2) var(--space-4);
  font-size: var(--fontsize-1);
  border-radius: 3px;
  color: var(--color-secondary-1);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-secondary-1);
  border: 1px solid var(--color-secondary-1);
  outline: none;
  cursor: pointer;
}

.login button:active {
  position: relative;
  top: 1px;
}

.login button:hover {
  color: var(--color-light-0);
  background: linear-gradient(
    60deg,
    var(--color-secondary-0),
    var(--color-secondary-1)
  );
  box-shadow: 2px 3px 0px 0px var(--color-secondary-2);
}

.login__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  width: 100%;
  user-select: none;
}

.login__footer__content {
  padding: var(--space-4) 0;
  color: var(--color-light-3);
  text-decoration: none;
}
