.drawer__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--color-dark-3);
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--color-light-0);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.125);
  transition: width 0.2s;
}

.drawer__content {
  display: flex;
  flex-direction: column;
  padding: var(--space-3);
  gap: var(--space-3);
}

.drawer__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-3);
  height: var(--navbar-height);
  border-bottom: 1px solid var(--color-light-3);
  font-size: var(--fontsize-3);
}

.drawer__header button {
  padding: 0;
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.drawer__header button:hover {
  color: var(--color-secondary-1);
}

.drawer__header button:disabled {
  color: var(--color-dark-0);
  cursor: not-allowed;
}

.drawer__title {
  margin-left: var(--space-4);
}

.drawer__title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-4);
  height: 100%;
  background-color: var(--color-secondary-1);
}

.drawer__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-4);
  gap: var(--space-3);
}

.drawer__footer button {
  padding: var(--space-2) var(--space-3);
  font-size: var(--fontsize-1);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.drawer__footer button:active {
  position: relative;
  top: 1px;
}

.drawer__footer button[type='button'] {
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-dark-1);
  border: 1px solid var(--color-dark-1);
}

.drawer__footer button[type='button']:hover {
  color: var(--color-secondary-2);
  border-color: var(--color-secondary-1);
  box-shadow: 2px 3px 0px 0px var(--color-secondary-1);
}

.drawer__footer button[type='submit'] {
  color: var(--color-accent-1);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-accent-1);
  border: 1px solid var(--color-accent-1);
}

.drawer__footer button[type='submit']:hover {
  color: var(--color-light-0);
  background: linear-gradient(
    60deg,
    var(--color-accent-0),
    var(--color-accent-1)
  );
  box-shadow: 2px 3px 0px 0px var(--color-accent-2);
}

.drawer__footer button[type='button']:disabled,
.drawer__footer button[type='submit']:disabled {
  color: var(--color-dark-0);
  border-color: var(--color-light-3);
  background-color: var(--color-light-0);
  background-image: none;
  box-shadow: 2px 3px 0px 0px var(--color-light-3);
  cursor: not-allowed;
}

@media screen and (max-width: 576px) {
  .drawer {
    width: 100%;
    transition: none;
  }
}
