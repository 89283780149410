.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--space-2);
  width: 100%;
  gap: var(--space-2);
}

.filter__select {
  flex: 1;
  width: 100%;
}

.filter__input {
  flex: 3;
  align-items: center;
  width: 100%;
}

.filter__button-panel {
  display: flex;
  align-items: center;
}

.filter__button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--color-dark-1);
}

.filter__button:hover {
  color: var(--color-secondary-1);
}

.filter__value {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--space-2);
  gap: var(--space-2);
}

.filter__value__button {
  padding: 0;
  color: var(--color-dark-2);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.filter__value__button:hover {
  color: var(--color-secondary-1);
}

.filter__value__clear {
  padding: var(--space-2) var(--space-3);
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .filter {
    flex-direction: column;
  }

  .filter__input {
    flex: 1;
  }
}
