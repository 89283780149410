.chip {
  display: inline-flex;
  align-items: center;
  padding: var(--space-2) var(--space-3);
  background-color: var(--color-light-2);
  color: var(--color-dark-1);
  border-radius: 15px;
  text-align: center;
  white-space: nowrap;
}

.chip__text {
  flex: 1;
  line-height: 1;
}

.chip__delete {
  margin-left: var(--space-2);
}
