.chip {
  display: inline-flex;
  align-items: center;
  padding: var(--space-1) var(--space-2);
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  border-radius: 15px;
  white-space: nowrap;
  font-size: var(--fontsize-1);
}

.chip__indicator {
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-right: var(--space-2);
  border-radius: 50%;
}
