.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeIn 0.25s forwards;
  animation-delay: 0.25s;
}

.loader__icon {
  position: relative;
  width: 64px;
  height: 64px;
}

.loader__icon__dot {
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: var(--color-accent-2);
  border-radius: 50%;
  animation: loading 1.2s linear infinite;
}

.loader__text {
  margin-left: var(--space-3);
  color: var(--color-accent-2);
  font-size: var(--fontsize-3);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
