.modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background-color: var(--color-dark-3);
  transition: opacity 0.3s ease-out;
}

.modal__wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.modal {
  background-color: var(--color-light-0);
  border-radius: 3px;
  overflow-y: auto;
}

.modal__content {
  padding: var(--space-3);
}

.modal__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-3);
  height: var(--navbar-height);
  border-bottom: 1px solid var(--color-light-3);
  font-size: var(--fontsize-3);
}

.modal__header button {
  padding: 0;
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.modal__header button:hover {
  color: var(--color-secondary-1);
}

.modal__header button:disabled {
  color: var(--color-dark-0);
  cursor: not-allowed;
}

.modal__title {
  margin-left: var(--space-4);
}

.modal__title::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--space-4);
  height: 100%;
  background-color: var(--color-secondary-1);
}

.modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-3);
  border-top: 1px solid var(--color-light-3);
  gap: var(--space-3);
}

.modal__footer button {
  padding: var(--space-2) var(--space-3);
  font-size: var(--fontsize-1);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.modal__footer button:active {
  position: relative;
  top: 1px;
}

.modal__footer__cancel {
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-dark-1);
  border: 1px solid var(--color-dark-1);
}

.modal__footer__cancel:hover {
  color: var(--color-secondary-2);
  border-color: var(--color-secondary-1);
  box-shadow: 2px 3px 0px 0px var(--color-secondary-1);
}

.modal__footer__submit {
  color: var(--color-accent-1);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-accent-1);
  border: 1px solid var(--color-accent-1);
}

.modal__footer__submit:hover {
  color: var(--color-light-0);
  background: linear-gradient(
    60deg,
    var(--color-accent-0),
    var(--color-accent-1)
  );
  box-shadow: 2px 3px 0px 0px var(--color-accent-2);
}

.modal__footer__cancel:disabled,
.modal__footer__submit:disabled {
  color: var(--color-dark-0);
  border-color: var(--color-light-3);
  background-color: var(--color-light-0);
  background-image: none;
  box-shadow: 2px 3px 0px 0px var(--color-light-3);
  cursor: not-allowed;
}

@media screen and (max-width: 576px) {
  .modal {
    width: 100%;
  }
}
