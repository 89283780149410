.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 4px;
  width: 100%;
  transition: visibility 0s linear 500ms, opacity 500ms;
}

.loader__bar {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(200, 200, 200, 0.4);
  overflow: hidden;
}

.loader__progress {
  position: absolute;
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: var(--color-accent-1);
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
