.loader::after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--color-dark-0);
  border-color: var(--color-dark-0) transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
